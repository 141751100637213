<template>
  <div>
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-input-group>
            <b-input-group-prepend is-text size="sm">
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Procurar" size="sm" v-model="search" debounce="800" type="search" :disabled="isLoading"/>
          </b-input-group>
        </b-col>
        <!-- <b-col lg="2">
          <b-button variant="relief-success" block size='sm' v-b-modal.modal-import>
            <feather-icon icon="UploadIcon" />
            Importar
          </b-button>
          <modal-import />
        </b-col> -->
        <b-col lg="2">
          <b-button variant="relief-primary" block size='sm' @click="addNew" v-b-toggle.sidebar-create-edit>
            <feather-icon icon="PlusIcon" />
            Cadastrar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <div v-show="modules.regionais.length > 0">
          <b-table-simple
            striped
            responsive
          >
            <b-thead>
              <b-tr class="text-center">
                <b-th>Nome</b-th>
                <b-th>Data de Criação</b-th>
                <b-th>Ações</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in modules.regionais">
                <b-td>
                {{tr.nome}}
                </b-td>
                <b-td class="text-center">
                  {{tr.created_at | dateTimeFormat}}
                </b-td>
                <b-td class="text-center">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="updateDetail(tr)"
                    v-b-toggle.sidebar-create-edit
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="deleteDetail(tr)"
                    v-b-modal.modal-delete
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-pagination
            v-model="currentPage"
            class="text-center float-right"
            :per-page="perPage"
            :total-rows="totalRows"
            aria-controls="tableSchools"
            size="sm"
            @change="handlePageChange"
          />
        </div>
        <div class="text-center" v-show="modules.regionais.length == 0">
          <no-data />
          <b-button  variant="relief-primary" v-b-toggle.sidebar-create-edit size="sm" @click='addNew'>
            <feather-icon icon="PlusIcon" />
            Cadastrar
          </b-button>
        </div>
      </b-overlay>
    </b-card>
    <modal-delete :key="keyDelete" :dataProp="dataDelete" @fetch-all="fetchModules"/>
    <create-edit :key="key" :dataProp="sidebarData" @fetch-all="fetchModules"/>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

// Meus componentes
import CreateEdit from "./CreateEdit.vue";
import ModalDelete from "../components/ModalDelete.vue";
import ModalImport from "../components/ModalImport.vue";
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    mapActions,
    mapState,
    CreateEdit,
    noData,
    ModalDelete,
    ModalImport
  },
  directives: {
    Ripple
  },
  data(){
    return {
     // Variáveis Global
      isLoading: true,
      key: 0,
      keyDelete: -1,
      
      //Paginação
      perPage: 9,
      currentPage: 1,
      totalRows: null,

      search: null,

      //Alimenta o modal de deletar
      dataDelete: {
        id: null,
        nome: null,
        pathAPI: 'regionais'
      },

      //Alimenta a sidebar de criar e editar
      sidebarData: {
        id: null,
        nome: null
      },
    }
  },
  computed: {
    ...mapState("connectAPI", ["modules"])
  },
  methods: {
    /*
    / Alimentar a sidebar (criar / editar) e o modal delete
    */
    addNew() {
      this.key++
      this.sidebarData.id = null;
    },

    updateDetail(tr){
      this.key++
      this.sidebarData.id = tr.id;
      this.sidebarData.nome = tr.nome;
    },

    deleteDetail(tr){
      this.keyDelete--
      this.dataDelete.id = tr.id;
      this.dataDelete.nome = tr.nome;
    },

    /*
    / Listar e paginar
    */
    fetchModules(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: 'regionais',
          paginated: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      this.fetchModules(true, value)
    },
  },
  created() {
    this.fetchModules();
  },
  watch: {
    perPage(){
      this.fetchModules()
    },
    search(){
      this.currentPage = 1;
      this.fetchModules(true, 1);
    }
  },
}
</script>
<style lang="scss">
</style>
